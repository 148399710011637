<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <SubBrands></SubBrands>
      </v-col>
    </v-row>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("brands.families") }}</v-card-title>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('brands.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.familyCategory"
                      v-bind:label="`Prod/Mat`"
                      :items="familyCategoryList"
                      item-text="label"
                      item-value="value"
                      filled
                      required
                      :rules="requiredRules"
                      @change="onChangeFamilyCategory"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="selected.description"
                      v-bind:label="$t('brands.description')"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.brandId"
                      v-bind:label="$t('brands.brand')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_brand"
                      :loading="isBrandSearching"
                      :items="filtered_brands"
                      hide-no-data
                      hide-selected
                      filled
                      clearable
                      required
                      :rules="requiredRules"
                      @change="onChangeBrand"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.parentId"
                      v-bind:label="$t('Parent')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_parent"
                      :loading="isParentSearching"
                      :items="filtered_parents"
                      hide-no-data
                      hide-selected
                      filled
                      clearable
                      @change="onChangeParent"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveClick(selected)">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">{{
            $t("brands.family_confirmdelete")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRef"
              id="family-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="families"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totalfamilies"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import Brands from "./Brands.vue";
import SubFamilies from "./SubFamilies.vue";
import SubBrands from "./SubBrands.vue";

export default {
  name: "Families",
  components: {
    Brands,
    SubFamilies,
    SubBrands,
  },
  data: function() {
    return {
      filterable_col_list: [
        "name",
        "description",
        "brand",
        "parent",
        "familyCategory",
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        name: {},
        description: {},
        brand: {},
        parent: {},
        familyCategory: {},
      },
      showEmpty: false,

      totalfamilies: 0,
      families: [],

      valid: true,
      requiredRules: [(v) => !!v || "Required"],
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      showEdit: false,
      dialogDelete: false,

      search_brand: null,
      isBrandSearching: false,
      filtered_brands: [],
      search_parent: null,
      isParentSearching: false,
      filtered_parents: [],

      familyCategoryList: [],
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async search_brand(val) {
      await this.search_brand_list(val);
    },
    async search_parent(val) {
      await this.search_parent_list(
        val,
        this.selected.familyCategory || "PRODUCT",
        null
      );
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: "7%",
          sortBy: "ASC",
          fixed: "left",
        },
        {
          key: "name",
          title: this.$t("salesforce.name"),
          field: "name",
          align: "left",
          sortBy: "",
          width: "15%",
          fixed: "left",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`brands/families/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "familyCategory",
          title: `Prod/Mat`,
          field: "familyCategory",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.familyCategoryLabel}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableCheckboxesOthers
                    v-model={this.filterCriteria["familyCategory"]}
                    dataList={this.familyCategoryList}
                    filterCriteria={this.filterCriteria["familyCategory"]}
                    itemvalue="value"
                    itemlabel="label"
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "familyCategory")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "description",
          title: this.$t("brands.description"),
          field: "description",
          align: "left",
          sortBy: "",
          width: "33%",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["description"]}
                    apiUrl={`brands/families/getfilterlist`}
                    columnName={`description`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["description"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "description")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "brand",
          title: this.$t("brands.brand"),
          field: "brand",
          align: "left",
          sortBy: "",
          width: "20%",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.brand && row.brand.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brand"]}
                    apiUrl={`brands/families/getfilterlist`}
                    columnName={`brand`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brand"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brand")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "parent",
          title: this.$t("Parent"),
          field: "parent",
          align: "left",
          sortBy: "",
          width: "15%",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.parent && row.parent.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["parent"]}
                    apiUrl={`brands/families/getfilterlist`}
                    columnName={`parent`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["parent"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "parent")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "actions",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: "10%",
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  medium
                  color="error"
                  on-click={() => this.onItemRemove(row.id)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          },
        },
      ];
    },
  },
  methods: {
    async search_brand_list(val) {
      if (!val) val = "";
      let fetch_url = "products/get_brand_list?filter_name=" + val;
      const resp = await ApiService.get(fetch_url);
      this.filtered_brands = resp.data;
    },
    async search_parent_list(val, familyCategory, brandId) {
      if (!val) val = "";
      let fetch_url = `brands/families/get_parent_list?familyCategory=${familyCategory}&filter_name=${val}`;
      if (brandId) {
        fetch_url += `&brandId=${brandId}`;
      }
      const resp = await ApiService.get(fetch_url);
      this.filtered_parents = resp.data;
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#family-table"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    async onChangeFamilyCategory() {
      await this.search_parent_list("", this.selected.familyCategory, null);
    },

    async onChangeBrand() {
      if (!this.selected.brandId) {
        this.selected.parentId = null;
        await this.search_parent_list("", this.selected.familyCategory, null);
      } else {
        await this.search_parent_list(
          "",
          this.selected.familyCategory,
          this.selected.brandId
        );
      }
    },

    async onChangeParent() {
      const foundParent = this.filtered_parents.find(
        (el) => el.id === this.selected.parentId
      );
      if (foundParent) {
        this.selected.brandId = foundParent.brandId;
      }
      if (
        !this.filtered_brands.map((el) => el.id).includes(this.selected.brandId)
      ) {
        await this.search_brand_list("");
      }
    },

    async createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        brandId: _.get(item, "brandId"),
        parentId: _.get(item, "parentId"),
        brand: _.get(item, "brand"),
        parent: _.get(item, "parent"),
        familyCategory: _.get(item, "familyCategory"),
      };
      if (!model.familyCategory) model.familyCategory = "PRODUCT";
      if (model.brand) {
        this.filtered_brands = [model.brand];
      }
      if (model.parent) {
        this.filtered_parents = [model.parent];
      }
      if (!model.id) {
        await this.search_brand_list("");
        await this.search_parent_list("", model.familyCategory, null);
      } else {
        if (this.filtered_brands.length === 0) await this.search_brand_list("");
        if (this.filtered_parents.length === 0)
          await this.search_parent_list("", model.familyCategory, null);
      }
      console.log(model);
      return model;
    },

    async onItemEdit(item) {
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    async onSaveClick(item) {
      console.log(item);
      if (this.$refs.form.validate()) {
        let body = item;
        if (body.id) {
          await ApiService.put(`brands/families/${body.id}`, body);
        } else {
          await ApiService.post(`brands/families`, body);
        }
        logInfo("Familias actualizada");
        this.showEdit = false;
        this.getDataFromApi();
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    onItemRemove(item_id) {
      console.log(item_id);
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`brands/families/${this.deleteItemId}`).then(() => {
          logInfo("Operadores eliminada");
          this.getDataFromApi();
        });
      }
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value,
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#family-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "brands/families";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.families = resp.data;
        if (this.families.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totalfamilies = resp.total;
        this.familyCategoryList = resp.familyCategoryList;
        this.close();
      } catch (error) {
        this.close();
      }
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    init() {
      this.getDataFromApi();
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style></style>
