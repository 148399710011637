<template>
  <v-card elevation="2" outlined shaped tile class="mb-2">
    <v-card-title
      >{{ $t("brands.families") }} && Sub{{
        $t("brands.families")
      }}</v-card-title
    >
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <ve-table
            ref="families_and_sub_families"
            id="table_families"
            fixed-header
            max-height="calc(100vh - 330px)"
            :columns="columns"
            :table-data="data"
            :border-around="true"
            :border-x="true"
            :border-y="true"
            :sort-option="sortOption"
            :cell-style-option="cellStyleOption"
          />
          <div v-show="showEmpty" class="empty-data">Data Empty.</div>
          <div class="table-pagination mt-2 text-right">
            <ve-pagination
              :total="totaldata"
              :page-index="pageIndex"
              :page-size="pageSize"
              :page-size-option="pageOptions"
              @on-page-number-change="pageNumberChange"
              @on-page-size-change="pageSizeChange"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "SubFamilies",
  data: function() {
    return {
      filterable_col_list: ["family_name", "parent_family_name", "sub_family_name"],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {},
      showEmpty: false,

      totaldata: 0,
      data: [],
    };
  },
  watch: {},
  computed: {
    columns() {
      return [
        {
          key: "family_name",
          title: this.$t("brands.family") + ' ' + this.$t("salesforce.name"),
          field: "family_name",
          align: "left",
          width: "33%",
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["family_name"]}
                    on-input={(value) => (this.filterCriteria["family_name"] = value)}
                    placeholder="Search Name"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "family_name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "sub_family_name",
          title: this.$t("brands.sub_family") + ' ' + this.$t("salesforce.name"),
          field: "sub_family_name",
          align: "left",
          width: "33%",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["sub_family_name"]}
                    on-input={(value) =>
                      (this.filterCriteria["sub_family_name"] = value)
                    }
                    placeholder="Search Sub"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "sub_family_name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "parent_family_name",
          title: this.$t("salesforce.Parent") + ' ' + this.$t("salesforce.name"),
          field: "parent_family_name",
          align: "left",
          width: "33%",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["parent_family_name"]}
                    on-input={(value) =>
                      (this.filterCriteria["parent_family_name"] = value)
                    }
                    placeholder="Search Parent"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "parent_family_name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
      ];
    },
  },
  methods: {
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#table_families"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value,
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "brands/families/get_tree";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.data = resp.data;
        if (this.data.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaldata = resp.total;
        this.close();
      } catch (error) {
        this.close();
      }
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = null;
      this.getDataFromApi();
    },

    init() {
      this.getDataFromApi();
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style></style>
